<template>
  <div class="user-main">
    <div class="title">
      <span>个人资料</span>
      <el-divider></el-divider>
      <div class="biaodan">
        <el-row>
          <el-form :model="form" :rules="rules" ref="form" label-width="100px">
            <el-col :md="24">
              <el-form-item label="头像:" prop="avatar">
                <Pictureupload :picimg="head" @change="picChange" />
              </el-form-item>
            </el-col>
            <el-col :md="24">
              <el-form-item label="用户名:" prop="username">
                <el-input v-model="form.username"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="24">
              <el-form-item label="性别:" prop="sex">
                <el-radio-group v-model="form.sex" size="small">
                  <el-radio-button label="1">男</el-radio-button>
                  <el-radio-button label="2">女</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :md="24">
              <el-form-item label="出生年月:" prop="birth">
                <el-date-picker
                  v-model="form.birthday"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="出生年月"
                  style="width: 100%;"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="24">
              <el-form-item label="手机号码" prop="phone">
                <el-input
                  v-model="form.phone"
                  placeholder="手机号码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="24">
              <div class="but">
                <el-button type="primary" @click="onSubmitput('form')"
                  >确认修改</el-button
                >
              </div>
            </el-col>
          </el-form>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserqueryid } from "@/api/login.js";
import Pictureupload from "@/components/user/Pictureupload";
import { setUser } from "@/api/login.js";
import { phone } from "@/utils/rules.js";
export default {
  components: {
    Pictureupload,
  },
  created() {
    let userinfo = localStorage.getItem("userInfo");
    if (userinfo) {
      this.form = JSON.parse(userinfo);
    } else {
      this.loadData();
    }
  },
  mounted() {
    this.head = this.form.avatar;
  },
  data() {
    return {
      form: {},
      head: "",
      rules: {
        username: [
          {
            required: true,
            message: "账号不能为空",
            trigger: "change",
          },
        ],
        phone: [{ required: false, trigger: "change", validator: phone }],
      },
    };
  },
  methods: {
    loadData() {
      let userid = localStorage.getItem("userid");
      getUserqueryid({
        id: userid,
      }).then((res) => {
        if (res.success) {
          this.form = res.result;
        }
      });
    },
    onSubmitput(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$confirm("是否确认修改?", "修改信息", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            setUser(this.form).then((res) => {
              if (res.success) {
                this.$message.success("修改成功");
                // 储存用户信息
                localStorage.setItem("userInfo", JSON.stringify(this.form));
                // 储存用户userid
                localStorage.setItem("userid", this.form.id);
              }
            });
          });
        }
      });
    },
    picChange(img) {
      this.form.avatar = img;
    },
  },
};
</script>
<style lang="scss" scoped>
.user-main {
  padding: 25px;
  .title {
    font-size: 16px;
    color: #999;
  }
  .biaodan {
    width: 313px;
  }
  .el-divider--horizontal {
    margin: 13px 0 38px;
  }
  .but {
    margin-left: 100px;
    margin-top: 10px;
    .el-button--primary {
      width: 212px;
      height: 44px;
      background-color: #6EB400;
      border-radius: 3px;
      border-color: #6EB400;
    }
  }
  ::v-deep.el-radio__input.is-checked .el-radio__inner {
    border-color: #6EB400;
    background-color: #6EB400;
  }
  ::v-deep.el-radio__input.is-checked + .el-radio__label {
    color: #6EB400;
  }
  ::v-deep.el-radio-button__orig-radio:checked + .el-radio-button__inner {
    border-color: #6EB400;
    background-color: #6EB400;
  }
}
  
</style>
